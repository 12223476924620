import React, { Component } from "react"
import axios from "axios";

import "./contactForm.scss"

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            mailSent: false,
            error: null,
        }
    }
    render() {
        return (
            <section className="section-contact-form">
                <div className="container space-2-bottom space-3-bottom--lg">
                    <div className="row space-1-bottom">
                        <div className="col-xs-12 col-sm-12 col-md-8">
                            <h2 className="space-1-bottom">Contacta con nosotros</h2>
                            <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                                <div className="row space-1-bottom">
                                    <div className="col-xs-12 col-sm-6 col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="name">Nombre</label>
                                            <input type="text" className="form-control" id="name" value={this.state.name} onChange={e => this.handleChange(e, 'name')} placeholder="Introduce tu nombre" required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className="form-control" id="email" value={this.state.email} onChange={e => this.handleChange(e, 'email')} placeholder="Introduce tu email" required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone">Teléfono</label>
                                            <input type="number" className="form-control" id="phone" value={this.state.phone} onChange={e => this.handleChange(e, 'phone')} placeholder="Introduce tu teléfono" required />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-7">
                                        <div className="form-group">
                                            <label htmlFor="subject">Asunto</label>
                                            <input type="text" className="form-control" id="subject" value={this.state.subject} onChange={e => this.handleChange(e, 'subject')} placeholder="Introduce el asunto" required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message">Mensaje</label>
                                            <textarea type="text" className="form-control" id="message" value={this.state.message} onChange={e => this.handleChange(e, 'message')} placeholder="Introduce el mensaje" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12">
                                        <button type="submit" className="section-contact-form__button">Enviar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4">
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div>
                                {this.state.error && <div className="section-contact-form__error">Ha ocurrido un error. Por favor, inténtelo más tarde</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    handleChange = (e, field) => {
        let value = e.target.value;
        let updateValue = {};
        updateValue[field] = value;
        this.setState(updateValue);
    };

    handleSubmit(event) {
        event.preventDefault();

        axios({
            method: "post",
            url: 'https://www.ibxagency.com/api/contact/index.php', //change this to correct endpoint 
            headers: { "content-type": "application/json" },
            data: this.state
        })
            .then(result => {
                if (result.data.sent) {
                    this.setState({ error: false });
                    window.location.replace("/contacto-gracias");
                } else {
                    this.setState({ error: true });
                }
            })
            .catch(error => this.setState({ error: error.message }));
    };

}

export default ContactForm